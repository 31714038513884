<template>
    <v-row no-gutters>
        <v-col cols="12">
            <!-- <v-row justify="start" class="mt-2 mx-4">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <p class="text-caption text-start">
                    <router-link :to="{ name: 'user-dashboard' }">Dashboard</router-link> &gt;
                    <router-link :to="{ name: 'user-organization-list' }">Organizations</router-link>
                    <template v-if="organization">
                    &gt; <router-link :to="{ name: 'organization-dashboard', params: { realm: this.$route.params.organizationId } }">{{ organization.name }}</router-link>
                    </template>
                </p>
                </v-col>
            </v-row> -->
            <v-row justify="center" class="py-5 px-10 mb-8 mt-2">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">

                    <v-card class="pa-0" v-if="productList">
                        <v-app-bar color="green darken-2" dark flat dense>
                            <v-app-bar-title>Products ({{ productList.length }})</v-app-bar-title>
                            <v-spacer/>
                            <!-- <v-btn icon @click="createNewItemDialog = true">
                                <font-awesome-icon :icon="['fas', 'plus']" style="font-size: 20px;" fixed-width></font-awesome-icon>
                            </v-btn> -->
                            <v-btn icon :to="{ name: 'organization-create-product', params: { organizationId: $route.params.organizationId } }">
                                <font-awesome-icon :icon="['fas', 'plus']" style="font-size: 20px;" fixed-width></font-awesome-icon>
                            </v-btn>
                        </v-app-bar>

                        <v-card-text v-if="productList.length === 0">
                            <p class="mb-0">No products.</p>
                            <!-- <v-btn style="background-color: green darken-2; color: #ffffff;" elevation="2" @click="createNewItemDialog = true">
                                Create
                            </v-btn> -->
                        </v-card-text>

                        <v-list dense v-if="productList.length > 0">
                            <v-list-item v-for="(item, idx) in productList" :key="idx" :to="{ name: 'organization-edit-product', params: { organizationId: $route.params.organizationId }, query: { id: item.id, published: item.published, t: Date.now() } }"> <!-- @click="openEditItemDialog(item)" -->
                                <v-list-item-content>
                                    <v-list-item-title>
                                        {{ item.name }}
                                        <v-chip v-if="item.is_active" label small class="px-1 ml-2 green darken-2 white--text">Active</v-chip>
                                        <v-chip v-if="!item.is_active" label small class="px-1 ml-2 amber black--text">Inactive</v-chip>
                                        <v-chip v-if="item.is_draft_pending" label small class="px-1 ml-2 amber darken-2 white--text">Draft</v-chip>
                                        <!-- <v-chip v-if="item.value === null" label small class="px-1 ml-2 green white--text">New</v-chip>
                                        <v-chip v-if="Array.isArray(item.value) && item.value.length === 1" label small class="px-1 ml-2 grey white--text">{{ ipaddr }}</v-chip>
                                        <template v-if="Array.isArray(item.value) && item.value.length > 1">
                                            <v-chip v-for="(ipaddr, idx) in item.value" :key="idx" label small class="px-1 ml-2 grey white--text">{{ ipaddr }}</v-chip>
                                        </template> -->
                                        <!-- <v-chip v-if="item.status === 'new'" label small class="px-1 ml-2 yellow">{{ item.status }}</v-chip>
                                        <v-chip v-if="item.status === 'pending'" label small class="px-1 ml-2 blue lighten-4">{{ item.status }}</v-chip>
                                        <v-btn icon small color="green darken-2" @click="openEditItemDialog(item)">
                                            <font-awesome-icon :icon="['fas', 'pencil-alt']" fixed-width></font-awesome-icon>
                                        </v-btn> -->
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>
                    </v-card>
                    <v-dialog v-model="createNewItemDialog" max-width="600">
                    <v-card class="pa-0" max-width="600">
                        <v-toolbar short flat color="white">
                            <v-toolbar-title class="green--text text--darken-2">Add Product</v-toolbar-title>
                        </v-toolbar>
                        <v-card-text class="px-5">
                            <!-- <p>A fee of <span class="font-weight-bold">$1.25/mo</span> for this host will be added to your subscription.</p> -->
                        </v-card-text>
                        <!-- <v-alert type="error" border="left" dense v-if="!dynamicSharedDomainList" class="mx-5">
                            <template #prepend>
                                <font-awesome-icon :icon="['fas', 'exclamation-triangle']" class="white--text text-body-1" fixed-width></font-awesome-icon>
                            </template>
                            <p class="ml-4 pb-0 mb-0">This feature is not currently available. Please contact customer support.</p>
                        </v-alert> -->
                        <v-form @submit.prevent="createItem" @keyup.enter.native.prevent="createItem" class="px-2">
                            <v-text-field v-model="newProductName" label="Product name" hint="The product name. This will be displayed to customers." ref="newProductNameInput"></v-text-field>
                            <v-select v-model="newProductType" :items="productTypeChoices" label="Product type"/>
                        </v-form>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn elevation="4" class="green white--text" @click="createItem" :disabled="!isNewItemFormComplete">
                                <span>Create</span>
                            </v-btn>
                            <v-btn text color="grey" @click="createNewItemDialog = false">
                                <span>Cancel</span>
                            </v-btn>
                            <v-spacer></v-spacer>
                        </v-card-actions>
                    </v-card>
                    </v-dialog>
                    <v-dialog v-model="editItemDialog" max-width="600">
                    <v-card class="pa-0" max-width="600">
                        <v-toolbar short flat color="white">
                            <v-toolbar-title class="green--text text--darken-2">Product</v-toolbar-title>
                        </v-toolbar>
                        <v-card-text class="px-5">
                            <p class="text-overline mb-0">Host</p>
                            <p class="mb-0">{{ editProductName }}</p>

                            <!-- <p class="text-overline mb-0 mt-4">IP Address</p>
                            <p v-if="!Array.isArray(editItemValue) || editItemValue.length === 0">Use the Dynamic DNS client software to update this record.</p>
                            <v-list v-if="Array.isArray(editItemValue) && editItemValue.length > 1">
                                <v-list-item v-for="(ipaddr, idx) in editItemValue" :key="idx">
                                    <v-list-item-title>{{ ipaddr }}</v-list-item-title>
                                </v-list-item>
                            </v-list> -->

                            <!-- <p class="text-overline mb-0">Client software</p>
                            <p class="mb-0">Link to download client</p>
                            <p class="mb-0">Link to get access token</p> -->

                            <p class="text-overline mb-0 mt-4">Other</p>
                            <p><router-link :to="{ name: 'organization-delete-product', params: { organizationId: this.$route.params.organizationId }, query: { id: this.editProductId } }">How to delete this product</router-link></p>

                            <!-- <v-form @submit.prevent="editItem" @keyup.enter.native.prevent="editItem" class="px-2">
                                <v-select v-model="editItemStatus" label="Status" :items="statusChoices"></v-select>
                            </v-form> -->

                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <!-- <v-btn elevation="4" class="green white--text" @click="editItem" :disabled="!isEditItemFormComplete">
                                <span>Save</span>
                            </v-btn> -->
                            <v-btn text color="grey" @click="editItemDialog = false">
                                <span>Close</span>
                            </v-btn>
                            <v-spacer></v-spacer>
                        </v-card-actions>
                    </v-card>
                    </v-dialog>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<style scoped>

</style>

<script>
import { mapState } from 'vuex';

export default {
    data: () => ({
        organization: null,
        productList: null,

        submitFormTimestamp: null,

        createNewItemDialog: false,
        newProductName: null,
        newProductType: null,

        editItemDialog: false,
        editProductId: null,
        editProductName: null,
        editProductType: null,
    }),
    computed: {
        ...mapState({
            session: (state) => state.session,
            productTypeChoices: (state) => state.productTypeChoices,
        }),
        isNewItemFormComplete() {
            return typeof this.newProductName === 'string' && this.newProductName.length > 0 && typeof this.newProductType === 'string' && this.newProductType.length > 0;
        },
        isEditItemFormComplete() {
            return typeof this.editProductId === 'string' && this.editProductId.length > 0 && typeof this.editProductName === 'string' && this.editProductName.length > 0;
        },
    },
    watch: {
        createNewItemDialog(newValue, oldValue) {
            if (newValue && !oldValue) {
                this.newProductName = '';
                this.newProductType = '';
                this.$nextTick(() => {
                    setTimeout(() => { this.$activateInput('newProductNameInput'); }, 1);
                });
            }
        },
    },
    methods: {
        async loadOrganization() {
            try {
                this.error = false;
                this.$store.commit('loading', { loadOrganization: true });
                const response = await this.$client.organization(this.$route.params.organizationId).currentOrganization.get();
                console.log(`organization/dashboard.vue: response ${JSON.stringify(response)}`);
                if (response) {
                    this.organization = response;
                } else {
                    // TODO: redirect back to organization list? show a not found message?
                }
            } catch (err) {
                console.error('failed to load organization', err);
                this.error = true;
            } finally {
                this.$store.commit('loading', { loadOrganization: false });
            }
        },
        async loadProductList() {
            try {
                this.$store.commit('loading', { loadProductList: true });
                const query = {};
                if (this.$route.query.published) {
                    query.published = this.$route.query.published; // 'true' or 'false'
                }
                const response = await this.$client.organization(this.$route.params.organizationId).product.search(query);
                if (response?.list) {
                    this.productList = response.list;
                }
            } catch (err) {
                console.error('loadProductList failed', err);
            } finally {
                this.$store.commit('loading', { loadProductList: false });
            }
        },
        async createItem() {
            if (Number.isInteger(this.submitFormTimestamp) && this.submitFormTimestamp + 500 > Date.now()) {
                return;
            }
            this.submitFormTimestamp = Date.now();
            try {
                this.$store.commit('loading', { createItem: true });
                const item = {
                    name: this.newProductName,
                    type: this.newProductType,
                };
                const response = await this.$client.organization(this.$route.params.organizationId).product.create(item);
                if (response?.isCreated && response.id) {
                    this.createNewItemDialog = false;
                    this.$bus.$emit('snackbar', { type: 'success', headline: 'OK' });
                    // this.productList.push(response.item);
                    this.loadProductList();
                } else {
                    this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed' });
                }
            } catch (err) {
                console.error('createItem failed', err);
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed' });
            } finally {
                this.$store.commit('loading', { createItem: false });
            }
        },
        async openEditItemDialog(item) {
            this.editProductId = item.id;
            this.editProductName = item.name;
            this.editItemDialog = true;
        },
        async editItem() {
            try {
                this.$store.commit('loading', { editItem: true });
                const response = await this.$client.organization(this.$route.params.organizationId).product.edit({ id: this.editProductId }, { name: this.editProductName });
                if (response?.isEdited) {
                    this.editItemDialog = false;
                    this.$bus.$emit('snackbar', { type: 'success', headline: 'OK' });
                    const idx = this.productList?.findIndex((item) => item.id === this.editProductId);
                    if (Number.isInteger(idx) && idx > -1) {
                        const product = this.productList[idx];
                        this.productList.splice(idx, 1, { ...product, name: this.editProductName });
                    } else {
                        this.loadProductList();
                    }
                } else {
                    this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed' });
                }
            } catch (err) {
                console.error('editItem failed', err);
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed' });
            } finally {
                this.$store.commit('loading', { editItem: false });
            }
        },
    },
    mounted() {
        this.loadOrganization();
        this.loadProductList();
    },
};
</script>
